<template>
  <!-- Informations générales communes à toutes les pages -->
  <div class="variables-etudiees-subview">
    <template v-if="isEditable">
      <teleport to="#header-action">
        <Btn
          v-if="helperService.userHasPermission('essaievenement_oedit')"
          class="action-btn"
          icon="create"
          color="white"
          hollow
          text="Modifier"
          :to="{
          name: 'protocolExperimentalTaskVariableStudiedListEdit',
          params: {
            id: $route.params.id,
            tid: $route.params.tid
          }
        }"
        />
      </teleport>
    </template>

  </div>
  <div class="tabs-material-subtitle no-padding-bottom">
    <Container>
      <h2>Variables étudiées</h2>
    </Container>
  </div>
  <div class="variables-etudiees-subview">
    <Section>
        <List
          v-if="!isLoading"
          :api="list.api"
          :header="list.headers"
          :items="list.col"
          :item-key="list.key"
          :listData="dataList"
          disableCheckbox
        >
        <template v-slot:cible="{ item }">
          {{ item?.cible?.valeur }}
        </template>
        <template v-slot:saisie_niveau="{ item }">
          <template v-if="item.saisie_niveau">
            <div>{{ item?.saisie_niveau.designation }}</div>
            <div v-if="item.saisie_niveau.uid === 'ORGANE'">
              Organe:&nbsp;{{ item.saisie_niveau.organe?.designation }}<br />
              Nombre d'échantillons:&nbsp;{{ item.saisie_niveau.organe?.nb_echantillons }}
            </div>
          </template>
        </template>
          <template v-slot:actions="{ item }">
            <Btn
              icon="visibility"
              default
              round
              grow
              :to="{
                name: 'protocolExperimentalTaskVariableStudiedSettings',
                params: {
                  id: $route.params.id,
                  tid: $route.params.tid,
                  veid: item.id
                }
              }"
            />
          </template>
        </List>
    </Section>
  </div>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'VariablesStudiedList',
  components: {
    Btn,
    List,
    Section,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['set-editable'],

  data() {
    return {
      isLoading: true,
      list: {
        api: {
          endpoint: null,
          params: {
            sort: 'id.ASC',
            page: null,
            limit: 0,
            filters: null,
          },
        },
        headers: [
          { label: 'Designation', dataField: 'designation' },
          { label: 'Niveau de saisie' },
          { label: 'Cible' },
        ],
        col: ['designation', 'saisie_niveau', 'cible'],
        key: 'id',
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      const endpoint = `protocole/${this.$route.params.id}/evenement/${this.$route.params.tid}/variable`
      const variables = await this.fetchService.get(endpoint)

      Promise.all(
        variables.data.map(async (variable) => {
          const niveau = await this.fetchService.get(`${endpoint}/${variable.id}/organe`)
          if (niveau.data.length > 0) {
            // eslint-disable-next-line no-param-reassign
            variable.saisie_niveau.organe = niveau.data.pop()
          } else {
            // eslint-disable-next-line no-param-reassign
            variable.saisie_niveau.organe = null
          }
        }),
      ).then(() => {
        this.dataList = this.helperService.cloneObject(variables.data)
        console.log(this.dataList)
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
